// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import {
  Box,
  Stack,
  Table,
  Button,
  Select,
  MenuItem,
  TableRow,
  TextField,
  TableHead,
  Container,
  TableBody,
  TableCell,
  IconButton,
  Typography,
  InputLabel,
  FormControl,
  InputAdornment,
  TableContainer,
} from '@mui/material';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { QrReader } from 'react-qr-reader';
import { useNavigate } from 'react-router-dom';
import ReactAudioPlayer from 'react-audio-player';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch, useSelector } from 'react-redux';

import Config from '../config';
import srcBeep from '../audio/beep.mp3';
import Videos from '../components/Videos';
import cartActions from '../redux/cart/actions';
import userActions from "../redux/user/actions";
import srcEmptyCart from '../images/empty-cart.png';
import LoadingButton from '../components/LoadingButton';
import { OpenpayResponseErrorLabels } from '../enums/EOpenpayResponseError';

const CartContainer = () => {
  let OpenPay = window?.OpenPay;

  const cart = useSelector((state) => state.cart);
  const user = useSelector((state) => state.user);
  const [delayScan, setDelayScan] = React.useState(1000);
  const [paymentSources, setPaymentSources] = React.useState([]);
  const [paymentSource, setPaymentSource] = React.useState('');
  const [search, setSearch] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [permission, setPermission] = React.useState(true);
  const [openCamara, setOpenCamara] = React.useState(false);
  const [paymentServiceConfig, setPaymentServiceConfig] = React.useState(null);
  const [deviceSessionId, setDeviceSessionId] = React.useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const QRref = React.useRef(null);
  const lastResult = React.useRef();
  const inputRef = React.useRef(null);
  const searchInputRef = React.useRef(null);
  const navigate = useNavigate();
  const whId = Config.apiWarehouseId;

  navigator.permissions.query({ name: 'camera' }).then((permission) => {
    if (permission.state !== 'granted') { 
      setPermission(false);
      return;
    }

    setPermission(true);
  });

  React.useEffect(() => {
    OpenPay = window?.OpenPay;

    if (!OpenPay || !paymentServiceConfig)  return;

    OpenPay.setId(paymentServiceConfig.merchantId);
    OpenPay.setApiKey(paymentServiceConfig.publicKey);
    OpenPay.setSandboxMode(process.env.REACT_SANDBOX_MODE);

    setDeviceSessionId(OpenPay.deviceData.setup('payment-form', 'deviceSessionId'));
  }, [window?.OpenPay, paymentServiceConfig]);

  const total = cart ? cart.reduce(
    (aux, current) => aux + (current.config.price * current.quantity),
    0
  ) : 0;

  // init
  React.useEffect(() => {
    if (!user) {
      navigate('/login');
    }

    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }

    getPaymentProcessorConfig();
    getMembership();
  }, []);

  const handleSearch = async (e) => {
    try {
      if (!search || search.length === 0) return;

      handleCloseCam();
      const response = await axios.get(`${Config.apiUrl}/products?$or[0][sku]=${search}&$or[1][barCode]=${search}&scope=warehouses&id=${whId}`, { 
        headers: { 
          'Authorization': `Bearer ${user.accessToken}` 
        } 
      });

      if(response.data.length === 0) {
        enqueueSnackbar('El producto no fue encontrado');
        throw new Error('sku not found');
      }
  
      const product = response.data[0];
      inputRef.current.audioEl.current.play();
      enqueueSnackbar('¡El producto fue agregado al carrito!');
      dispatch(cartActions.addCart(product));
      searchInputRef.current.focus();
    } catch (error) {
      console.error(error);
    } finally {
      setSearch('');
    }
  };

  const getPaymentProcessorConfig = () => {
    axios
    .get(`${Config.zellshipApiUrl}/operative_payment_services_by_warehouse`, {
      params: {
        warehouseId: whId,
        paymentServiceCode: 'openpay',
      }
    })
    .then((response) => {
      const operativePaymentServices = response.data;
      
      const selectOperativePaymentService = operativePaymentServices.find(operativePaymentService => {
        return operativePaymentService.payment_strategy.code === 'card';
      });
      
      if (!selectOperativePaymentService) return;

      setPaymentServiceConfig(selectOperativePaymentService.paymentServiceConfig);
    })
    .catch((error) => {
      console.error(error);
      setPaymentServiceConfig(null);
    });
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);

      if (!cart || cart.length === 0) throw new Error('El carrito esta vacio');
      if (!paymentSource) throw new Error('Método de pago no seleccionado'); // hotfix/internal
      
      const items = cart.map((item) => {
        return {
          entityId: item.id,
          entityType: 'product',
          selection: item.id,
          qty: item.quantity
        }
      });

      const newData = {
        warehouseId: whId,
        items: items,
        amount: total,
        paymentServiceCode: 'openpay',
        config: {
          paymentSourceId: paymentSource,
          deviceSessionId,
        },
      };

      const { data } = await axios.post(
        `${Config.apiUrl}/self_checkout`, 
        newData,
        { headers: { 'Authorization': `Bearer ${user.accessToken}` } },
      );

      if (!data.order) throw new Error('error in checkout');

      if (data.url) {
        window.location.href = data.url;
        return;
      }

      enqueueSnackbar('¡Se ha registrado correctamente!');
      setSearch('');
      dispatch(cartActions.deleteCart());
      // TODO: check if user has cookies to close session
      dispatch(userActions.deleteUser());
      navigate('/thanks');
    } catch (error) {
      console.error(error);
      const errorCode = error?.response?.data?.data?.error?.error_code;
      console.log({ errorCode });
      let errorMsg = '¡Ha ocurrido un error inesperado !';
      if (errorCode) {
        errorMsg = OpenpayResponseErrorLabels[errorCode] ?? errorMsg;
      }
      enqueueSnackbar(errorMsg);
    } finally {
      setLoading(false);
    }
  };

  const getMembership = () => {
    axios
      .get(`${Config.apiUrl}/payment_sources?warehouseId=${whId}&paymentServiceCode=openpay`,
      { headers: { 'Authorization': `Bearer ${user.accessToken}` } })
      .then((response) => {
        if (response && response.status === 200 && response.data && response.data.paymentSources) {
          setPaymentSources(response.data.paymentSources)
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar('¡Ha ocurrido un error inesperado !');
      });
  }

  const handleCloseCam = () => {
    setOpenCamara(false);
    setSearch('');
    setDelayScan(false)
  };

  return (
    <Container maxWidth='sm'>
      <form action='#' id='payment-form'>
        <input name='deviceSessionId' id='deviceSessionId' hidden/>
        <input name='token_id' id='token_id' hidden/>
      </form>
      <Box>
        <Typography className='title' align='center'>
          Bienvenido a tu cava
        </Typography>
        <Stack
          direction='row'
          justifyContent='center'
          spacing={2}
          sx={{ marginTop: '10px' }}
        >
          {!openCamara ? (
            <Stack direction='column'>
              <Button
                className='button-black'
                variant='contained'
                onClick={() => {
                  setOpenCamara(true);
                  setDelayScan(false);
                  lastResult.current = ''
                }}
              >
                Escanear productos
              </Button>
            </Stack>
          ) : null}
        </Stack>
        <Box ref={QRref}>
          <QrReader
            constraints={{
              facingMode: 'environment',
            }}
            facingMode={'environment'}
            resolution={600}
            delay={5000}
            scanDelay={delayScan}
            videoId={'videoId'}
            showViewFinder
            onResult={(result, error) => {

              if (!result) return;

              if (lastResult.current === result.text) return;

              lastResult.current = result.text;
              setSearch(result.text);
            }}
            containerStyle={{
              display: permission && openCamara ? 'block' : 'none',
            }}
          />
        </Box>
        {openCamara ? (
          <Stack direction='row' justifyContent='center' spacing={2}>
            <Button
              variant='contained'
              className='button-red'
              onClick={() => {
                handleCloseCam();
                lastResult.current = ''
              }}
            >
              Cancelar
            </Button>
          </Stack>
        ) : null}
        <Stack style={{ margin: '20px 0px' }}>
          <TextField
            id='sku-input'
            inputRef={searchInputRef}
            label='Buscar por sku'
            variant='outlined'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') { handleSearch(e); }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position='end'
                  style={{ 
                    cursor: 'pointer',
                    display: 'flex'
                  }}
                  onClick={handleSearch}
                >
                  <IconButton 
                    className='icon-button-black'
                    style={{ margin: '0' }}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Stack>

        <ReactAudioPlayer
          style={{ display: 'none' }}
          ref={inputRef}
          src={srcBeep}
          controls
        />

        <TableContainer >
          <Table >
            <TableHead>
              <TableRow>
                <TableCell >Producto</TableCell>
                <TableCell >SKU</TableCell>
                <TableCell >Precio</TableCell>
                <TableCell >Cantidad</TableCell>
                <TableCell >Total</TableCell>
                <TableCell >Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cart.length ? cart.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell >{row.nameEs}</TableCell>
                  <TableCell >{row.sku}</TableCell>
                  <TableCell >${row.config ? row.config.price : 0}</TableCell>
                  <TableCell>{row.quantity}</TableCell>
                  <TableCell>${(row.config ? row.config.price : 0) * row.quantity}</TableCell>
                  <TableCell >
                    <IconButton component='label' onClick={() => {
                      dispatch(cartActions.deleteProduct(row.id));
                    }} >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>

                </TableRow>
              )) : <TableRow>
                <TableCell colSpan={12}>
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent:'center', 
                  }}>
                    <img 
                      src={srcEmptyCart} 
                      width='80%' 
                      alt='empty cart' 
                    />
                  </Box>
                </TableCell>

              </TableRow>}
            </TableBody>

          </Table>
        </TableContainer>
      </Box>

      <Box sx={{ 
        width: '100%',
        marginTop: '1rem'
      }}>
        <FormControl fullWidth>
          <InputLabel id='labelId'>Seleccione método de pago</InputLabel>
          <Select
            labelId='labelId'
            id='demo-simple-select'
            value={paymentSource}
            error={!paymentSource}
            label='Seleccione método de pago'
            onChange={(e: any) => {
              const cid = e.target.value;
              if (cid) {
                setPaymentSource(cid)
                // let found = paymentSources.find((citem: any) => citem.id === cid)
              } else {
                setPaymentSource('')
              }
            }}
          >
            {paymentSources.map((citem: any) => {
              let tmptitle = 'irreconocida'
              if (citem) {
                if (citem.last4) {
                  tmptitle = '**** **** **** '+citem.last4
                }
                if (citem.brand) {
                  tmptitle += ' | '+citem.brand
                }
                if (citem.expirationYear) {
                  tmptitle += ' | Año '+citem.expirationYear
                }
                if (citem.expirationMonth) {
                  tmptitle += ' | Mes '+citem.expirationMonth
                }
              }
              return <MenuItem key={citem.id} value={citem.id}>{tmptitle}</MenuItem>;
            })}
          </Select>
        </FormControl>
      </Box>

      <Typography className='paragraph' align='right'>
        Total: {`$${total}`}
      </Typography>

      <Stack
        direction='row'
        justifyContent='center'
        spacing={2}>

        <LoadingButton 
          loading={loading}
          disabled={!cart.length}
          className='button-black'
          onClick={(e: any) => handleSubmit(e)}
        >
          Pagar
        </LoadingButton>

      </Stack>

      <Videos />
    </Container>
  );
};

export default CartContainer;
